import React, {FC, useEffect, useState} from 'react'
import {Invoice, ProductLine} from '../data/types'
import {initialInvoice, initialProductLine} from '../data/initialData'
import EditableInput from './EditableInput'
import EditableCalendarInput from './EditableCalendarInput'
import Document from './Document'
import Page from './Page'
import View from './View'
import Text from './Text'
import format from 'date-fns/format'
import headerInvoice from '../images/header.png'
import footerInvoice from '../images/footer.png'
import bankakLogo from '../images/bankak.png'
import getRandomInt from "../config/randomInt";

interface Props {
  data?: Invoice
  pdfMode?: boolean
  onChange?: (invoice: Invoice) => void
  download?:boolean
}
const randomInt = getRandomInt();
const InvoicePage: FC<Props> = ({ data, pdfMode,download, onChange }) => {
  const [invoice, setInvoice] = useState<Invoice>(data ? { ...data } : { ...initialInvoice })
  const [subTotal, setSubTotal] = useState<number>()
  const [saleTax, setSaleTax] = useState<number>()
  const [dateValue, setDateValue] = useState<any>(new Date())

  const dateFormat = 'MMM dd, yyyy'
  // const invoiceDate = new Date()
  let invoiceDate = invoice.invoiceDate !== '' ? new Date(invoice.invoiceDate) : new Date()
  const invoiceDueDate =
    invoice.invoiceDueDate !== ''
      ? new Date(invoice.invoiceDueDate)
      : new Date(invoiceDate.valueOf())

  if (invoice.invoiceDueDate === '') {
    invoiceDueDate.setDate(invoiceDueDate.getDate() + 30)
  }

  const handleChange = (name: keyof Invoice, value: string | number) => {
    if (name !== 'productLines') {
      const newInvoice = { ...invoice }

      if (name === 'logoWidth' && typeof value === 'number') {
        newInvoice[name] = value
      } else if (name !== 'logoWidth' && typeof value === 'string') {
        newInvoice[name] = value
      }

      setInvoice(newInvoice)
    }
  }

  const handleProductLineChange = (index: number, name: keyof ProductLine, value: string) => {
    const productLines = invoice.productLines.map((productLine, i) => {
      if (i === index) {
        const newProductLine = { ...productLine }

        if (name === 'description') {
          newProductLine[name] = value
        } else {
          if (
            value[value.length - 1] === '.' ||
            (value[value.length - 1] === '0' && value.includes('.'))
          ) {
            newProductLine[name] = value
          } else {
            const n = parseFloat(value)

            newProductLine[name] = (n ? n : 0).toString()
          }
        }

        return newProductLine
      }

      return { ...productLine }
    })

    setInvoice({ ...invoice, productLines })
  }

  const handleRemove = (i: number) => {
    const productLines = invoice.productLines.filter((productLine, index) => index !== i)

    setInvoice({ ...invoice, productLines })
  }

  const handleAdd = () => {
    const productLines = [...invoice.productLines, { ...initialProductLine }]

    setInvoice({ ...invoice, productLines })
  }

  const calculateAmount = (quantity: string, rate: string) => {
    const quantityNumber = +quantity
    const rateNumber = +rate
    return quantityNumber && rateNumber ? quantityNumber * rateNumber : 0
  }

  useEffect(() => {
    let subTotal = 0

    invoice.productLines.forEach((productLine) => {
      const quantityNumber = parseFloat(productLine.quantity)
      const rateNumber = parseFloat(productLine.rate)
      const amount = quantityNumber && rateNumber ? quantityNumber * rateNumber : 0

      subTotal += amount
    })

    setSubTotal(subTotal)
  }, [invoice.productLines])

  useEffect(() => {
    const match = invoice.taxLabel.match(/(\d+)%/)
    const taxRate = match ? parseFloat(match[1]) : 0
    const saleTax = subTotal ? (subTotal * taxRate) / 100 : 0

    setSaleTax(saleTax)
  }, [subTotal, invoice.taxLabel])

  useEffect(() => {
    if (onChange) {
      onChange(invoice)
    }
  }, [onChange, invoice])

  return (
     <div>
       <Document pdfMode={pdfMode}>
         <Page  className="invoice-wrapper" pdfMode={pdfMode}>
           <div className="p-20">
             <View className="w-100" pdfMode={pdfMode}>
               <img
                   src={headerInvoice}
                   className="image_header"
                   alt=""/>
             </View>
             <div className="w-100 flex mt-5">
               <div className="flex mt-3">
                 <div className="fs-13 a-color mt-3">التاريخ</div>
                 <EditableCalendarInput
                     className='fs-13 bold mr-10'
                     value={format(dateValue, dateFormat)}
                     selected={dateValue}
                     onChange={(date) => {
                          // console.log(format(date, dateFormat))
                          setDateValue(date)
                       }
                     }
                     pdfMode={pdfMode}
                 />
               </div>
               <div className="w-45 flex mt-3">
                 <div className="w-40 fs-13 a-color mt-3">رقم الفاتورة</div>
                 <div className="w-40 fs-13 p-color mt-3 bold">{randomInt+'#'}</div>
               </div>
             </div>
             <View className="flex w-100" pdfMode={pdfMode}>
                 <EditableInput
                     className="w-17 p-color fs-13"
                     value={invoice.billTo}
                     onChange={(value) => handleChange('billTo', value)}
                     pdfMode={pdfMode}
                 />
                 <EditableInput
                     className="w-70 p-color bold fs-13"
                     placeholder="اسم العميل"
                     value={invoice.clientName}
                     onChange={(value) => handleChange('clientName', value)}
                     pdfMode={pdfMode}
                 />
             </View>

             <View className="w-100 bg-dark flex" pdfMode={pdfMode}>
               <View className="w-40 mr-10" pdfMode={pdfMode}>
                 <div className="w-40 fs-13 white mt-3">البيان</div>
               </View>
               <View className="w-17" pdfMode={pdfMode}>
                 <div className="w-40 fs-13 white mt-3">الكمية</div>
               </View>
               <View className="w-17" pdfMode={pdfMode}>
                 <div className="w-40 fs-13 white mt-3">السعر</div>
               </View>
               <View className="w-17" pdfMode={pdfMode}>
                 <div className="w-40 fs-13 white mt-3">المجموع</div>
               </View>
             </View>

             {invoice.productLines.map((productLine, i) => {
               return pdfMode && productLine.description === '' ? (
                   <Text key={i}></Text>
               ) : (
                   <View key={i} className="w-100 row flex" pdfMode={pdfMode}>
                     <EditableInput
                         className="w-45 dark fs-13 bold p-0 m-0"
                         placeholder="ادخل اسم الخدمة او المتنج"
                         value={productLine.description}
                         onChange={(value) => handleProductLineChange(i, 'description', value)}
                         pdfMode={pdfMode}
                     />
                     <EditableInput
                         className="w-17 dark fs-13 bold p-0 m-0"
                         value={productLine.quantity}
                         onChange={(value) => handleProductLineChange(i, 'quantity', value)}
                         pdfMode={pdfMode}
                     />
                     <EditableInput
                         className="w-17 dark fs-13 bold p-0 m-0"
                         value={productLine.rate.toString()}
                         onChange={(value) => handleProductLineChange(i, 'rate', value)}
                         pdfMode={pdfMode}
                     />
                     <Text className="w-17 dark fs-13 bold p-0 m-0" pdfMode={pdfMode}>
                       {calculateAmount(productLine.quantity, productLine.rate).toString()}
                     </Text>
                     {!pdfMode && (
                         <button
                             className="link row__remove mb-0"
                             aria-label="Remove Row"
                             title="Remove Row"
                             onClick={() => handleRemove(i)}
                         >
                           <span className="icon icon-remove bg-red"></span>
                         </button>
                     )}
                   </View>
               )
             })}

             <View className="flex" pdfMode={pdfMode}>
               <View className="w-60 mt-10" pdfMode={pdfMode}>
                 {!pdfMode && !download && (
                     <button className="invoice-btn fs-13" onClick={handleAdd}>
                       اضافة عنصر +
                     </button>
                 )}
               </View>
             </View>
             <View className="w-90 bg-dark flex bottom-widget aps-al-b-40" pdfMode={pdfMode}>
               <View className="w-90 mr-10" pdfMode={pdfMode}>
                 <EditableInput
                     className="bold white fs-13"
                     value={invoice.totalLabel}
                     onChange={(value) => handleChange('totalLabel', value)}
                     pdfMode={pdfMode}
                 />
               </View>
               <View className=" flex" pdfMode={pdfMode}>
                 <Text className="right bold white w-auto" pdfMode={pdfMode}>
                   {(typeof subTotal !== 'undefined' && typeof saleTax !== 'undefined'
                           ? subTotal
                           : 0
                   ).toFixed(0)}
                 </Text>
               </View>
               <View className="w-17 flex" pdfMode={pdfMode}>
                 <EditableInput
                     className="w-50 white bold right fs-13"
                     value={invoice.currency}
                     onChange={(value) => handleChange('currency', value)}
                     pdfMode={pdfMode}
                 />
               </View>
             </View>
             <View className="w-90 bottom-widget aps-al-b-20" pdfMode={pdfMode}>
               <View className="flex w-100" pdfMode={pdfMode}>
                 <img
                     src={bankakLogo}
                     className="image w-17 mr--20"
                     alt=""/>
                 <EditableInput
                     className="w-17 p-color fs-13 mr--15"
                     value={invoice.bankakLabel}
                     onChange={(value) => handleChange('bankakLabel', value)}
                     pdfMode={pdfMode}
                 />
                 <EditableInput
                     className="w-70 p-color bold fs-14"
                     placeholder="رقم الحساب"
                     value={invoice.bankakAccount}
                     onChange={(value) => handleChange('bankakAccount', value)}
                     pdfMode={pdfMode}
                 />
               </View>
               <img
                   src={footerInvoice}
                   className="image_header"
                   alt=""/>
             </View>
             <div className="w-90 bottom-widget aps-al-b-10 gray-color center">
               <div className="fs-13 monospace">Developed by Mohtady Behairy</div>
             </div>
           </div>
         </Page>
       </Document>
     </div>
  )
}

export default InvoicePage
